<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Keywords'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Keywords Create'"
      :title="'Keywords Create'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Submit</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Keyword</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'keyword'"
              :label="'keyword'"
              :type="'text'"
              :placeholder="'Keyword'"
              :error-messages="validationErrors['keyword']"
              isBordered
            />
          </div>

          <p class="form-create__label">Parent Keyword</p>
          <div class="input-container">
            <div class="form-create__keyword">
              <input
                type="text"
                @focus="handleFocusParent"
                @blur="handleBlurParent"
                @input="debouncedGetParent"
                v-model="formDataToSend.parent_name"
                class="form-create__keyword-input"
              />

              <div v-if="parentList" class="form-create__keyword-list">
                <template v-if="parentArray.length > 0">
                  <div
                    @click="handleChooseItemParent(item)"
                    v-for="item in parentArray"
                    :key="item.id"
                    class="form-create__keyword-item"
                  >
                    {{ item.keyword.toLowerCase() }}
                  </div>
                </template>
                <div v-else class="form-create__keyword-item">NO keywords</div>
              </div>
            </div>

            <Notification :error-messages="validationErrors['keywordId']" />
          </div>

          <p class="form-create__label">Categories</p>
          <input
            v-model="categorySearch"
            class="form-create__search"
            type="text"
            placeholder="Search category..."
            @input="debouncedGetCategories"
          />

          <div class="input-container checkbox-container checkbox-container_margin">
            <div class="checkbox-container__item">
              <InputRadio
                :set-value="formDataToSend"
                :id="'category'"
                :values="categoriesList"
                :name="'fullName'"
                :value="'id'"
              />
            </div>
          </div>

          <Notification :error-messages="validationErrors['categoryId']" />

          <template v-if="attributeArray.length > 0">
            <p class="form-create__label">Attributes</p>
            <div class="input-container checkbox-container">
              <div v-for="item in attributeArray" :key="item.id" class="checkbox-container__item">
                <MainCheckbox
                  :label="item.name"
                  :id="item.id + ''"
                  :set-value="formDataToSend.attributes"
                />
              </div>
            </div>
          </template>

          <p class="form-create__label">Formats</p>
          <div class="input-container checkbox-container">
            <div v-for="item in templatesTypesList" :key="item.id" class="checkbox-container__item">
              <MainCheckbox
                :label="item.name"
                :id="item.id + ''"
                :set-value="formDataToSend.type"
              />
            </div>
          </div>

          <p class="form-create__label">Seasonality</p>
          <div class="input-container checkbox-container">
            <div v-for="item in months" :key="item.id" class="checkbox-container__item">
              <MainCheckbox
                :label="item.name"
                :id="item.id + ''"
                :set-value="formDataToSend.months"
              />
            </div>
          </div>

          <p class="form-create__label">Sizes</p>
          <div class="input-container checkbox-container">
            <div v-for="item in templatesSizesList" :key="item.id" class="checkbox-container__item">
              <MainCheckbox
                :label="item.name"
                :id="item.id + ''"
                :set-value="formDataToSend.size"
              />
            </div>
          </div>

          <p class="form-create__label">Key Points</p>
          <div class="input-container">
            <InputTextarea
              :set-value="formDataToSend"
              :id="'key_points'"
              :label="'key_points'"
              :placeholder="''"
              :error-messages="validationErrors['keyPoints']"
              isBordered
            />
          </div>

          <p class="form-create__label">Indents and Margins</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'indents_and_margins'"
              :label="'indents_and_margins'"
              :type="'text'"
              :placeholder="'Indents and Margins'"
              :error-messages="validationErrors['indentsAndMargins']"
              isBordered
            />
          </div>

          <p class="form-create__label">Images</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'images'"
              :label="'images'"
              :type="'text'"
              :placeholder="'images'"
              :error-messages="validationErrors['images']"
              isBordered
            />
          </div>

          <p class="form-create__label">Typography</p>
          <div class="input-container">
            <TextEditor
              :set-value="formDataToSend"
              :id="'typography'"
              :placeholder="''"
              :error-messages="validationErrors['typography']"
            />
          </div>

          <p class="form-create__label">Template Overview</p>
          <div class="input-container">
            <TextEditor
              :set-value="formDataToSend"
              :id="'template_overview'"
              :placeholder="''"
              :error-messages="validationErrors['templateOverview']"
            />
          </div>

          <p class="form-create__label">Preview Requirements</p>
          <div class="input-container">
            <InputTextarea
              :set-value="formDataToSend"
              :id="'preview_requirements'"
              :label="'preview_requirements'"
              :placeholder="''"
              :error-messages="validationErrors['preview_requirements']"
              isBordered
            />
          </div>

          <p class="form-create__label">Examples</p>
          <div class="input-container">
            <InputTextarea
              :set-value="formDataToSend"
              :id="'examples'"
              :label="'examples'"
              :placeholder="''"
              :error-messages="validationErrors['examples']"
              isBordered
            />
          </div>

          <p class="form-create__label">Pages</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'pages'"
              :label="'pages'"
              :type="'text'"
              :placeholder="'Pages'"
              :error-messages="validationErrors['pages']"
              isBordered
            />
          </div>

          <p class="form-create__label">Target audience</p>
          <div class="input-container">
            <InputTextarea
              :set-value="formDataToSend"
              :id="'target_audience'"
              :label="'target_audience'"
              :placeholder="''"
              :error-messages="validationErrors['target_audience']"
              isBordered
            />
          </div>

          <div
            @click="toggleAllFields"
            :class="['form-create__all-button', isAllFields && 'active']"
          >
            See All Fields
            <IconSelect />
          </div>

          <!-- No active field -->

          <transition name="accordion" @before-enter="beforeEnter" @enter="enter" @leave="leave">
            <div v-if="isAllFields" class="form-create__all-container">
              <p class="form-create__label">Template Requirements</p>
              <div class="input-container">
                <InputTextarea
                  :set-value="formDataToSend"
                  :id="'template_requirements'"
                  :label="'template_requirements'"
                  :placeholder="''"
                  :error-messages="validationErrors['templateRequirements']"
                  isBordered
                />
              </div>

              <p class="form-create__label">Template Diagram</p>
              <div class="input-container">
                <InputTextarea
                  :set-value="formDataToSend"
                  :id="'template_diagram'"
                  :label="'template_diagram'"
                  :placeholder="''"
                  :error-messages="validationErrors['templateDiagram']"
                  isBordered
                />
              </div>

              <p class="form-create__label">Information must have</p>
              <div class="input-container">
                <InputTextarea
                  :set-value="formDataToSend"
                  :id="'information_must_have'"
                  :label="'information_must_have'"
                  :placeholder="''"
                  :error-messages="validationErrors['informationMustHave']"
                  isBordered
                />
              </div>

              <p class="form-create__label">Information can be added</p>
              <div class="input-container">
                <InputTextarea
                  :set-value="formDataToSend"
                  :id="'information_can_be_added'"
                  :label="'information_can_be_added'"
                  :placeholder="''"
                  :error-messages="validationErrors['informationCanBeAdded']"
                  isBordered
                />
              </div>

              <p class="form-create__label">Planned labor intensity in hours</p>
              <div class="input-container">
                <InputText
                  :set-value="formDataToSend"
                  :id="'planned_labor_intensity_in_hours'"
                  :label="'planned_labor_intensity_in_hours'"
                  :type="'text'"
                  :placeholder="'Planned labor intensity in hours'"
                  :error-messages="validationErrors['planned_labor_intensity_in_hours']"
                  isBordered
                />
              </div>

              <p class="form-create__label">AI Requirements</p>
              <div class="input-container">
                <TextEditor
                  :set-value="formDataToSend"
                  :id="'ai_requirements'"
                  :placeholder="''"
                  :error-messages="validationErrors['aiRequirements']"
                />
              </div>

              <div class="form-create__btn">
                <MainButton @click.native="handleAiRequirements">Get AI Requirements</MainButton>
              </div>
            </div>
          </transition>

          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Submit</MainButton>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import categoriesApi from "~/api/categories";
import referencesApi from "~/api/references";
import templateApi from "~/api/templates";
import { debounce } from "lodash";

import IconSelect from "~/assets/images/icons/select-icon.svg";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import InputTextarea from "~/components/atoms/inputs/InputTextarea.vue";
import InputRadio from "~/components/atoms/inputs/InputRadio.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import Notification from "~/components/molecules/Notification.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";
import TextEditor from "~/components/atoms/inputs/TextEditor.vue";

export default {
  name: "KeywordCreate",
  metaInfo: {
    title: "Keyword Create",
  },
  data() {
    return {
      parentList: false,
      isAllFields: false,
      formDataToSend: {
        keyword: "",
        category: null,
        size: {},
        type: {},
        attributes: {},
        months: {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
          7: false,
          8: false,
          9: false,
          10: false,
          11: false,
          12: false,
        },
        information_must_have: "",
        information_can_be_added: "",
        examples: "",
        pages: "",
        target_audience: "",
        preview_requirements: "",
        planned_labor_intensity_in_hours: "",
        parent_name: "",
        parent_id: null,
        ai_requirements: "",
        key_points: "",
        template_requirements: "",
        template_diagram: "",
        template_overview: "",
        indents_and_margins: "",
        typography: "",
        images: "",
      },
      months: [
        { id: 1, name: "January" },
        { id: 2, name: "February" },
        { id: 3, name: "March" },
        { id: 4, name: "April" },
        { id: 5, name: "May" },
        { id: 6, name: "June" },
        { id: 7, name: "July" },
        { id: 8, name: "August" },
        { id: 9, name: "September" },
        { id: 10, name: "October" },
        { id: 11, name: "November" },
        { id: 12, name: "December" },
      ],
      parentArray: [],
      categoriesList: [],
      categorySearch: "",
      attributeArray: [],
    };
  },

  watch: {
    "formDataToSend.category"(newValue, oldValue) {
      this.getAttributes();
    },
  },

  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    InputRadio,
    Notification,
    MainCheckbox,
    InputTextarea,
    TextEditor,
    IconSelect,
  },
  mounted() {
    this.getCategories();
    this.getData();
  },
  computed: {
    ...mapState("templates", ["error", "loadingProcess", "validationErrors"]),
    ...mapState("references", ["templatesSizesList", "templatesTypesList"]),
    getFirstLink() {
      return ROUTE.KEYWORDS_LIST.replace(":page", 1);
    },
  },
  methods: {
    async getData() {
      await referencesApi
        .getTemplatesSizesAll()
        .then((res) => {
          this.$store.commit("references/setTemplatesSizesList", res.data.items);
        })
        .catch((error) => {})
        .finally(() => {});

      await referencesApi
        .getTemplatesTypes()
        .then((res) => {
          this.$store.commit("references/setTemplatesTypesList", res.data[0].data);
        })
        .catch((error) => {})
        .finally(() => {});

      await this.setSize();
      await this.setType();
    },
    setSize() {
      const size = this.templatesSizesList;
      for (let i = 0; size.length > i; i++) {
        this.$set(this.formDataToSend.size, size[i].id, false);
      }
    },
    setType() {
      const type = this.templatesTypesList;
      console.log(this.templatesTypesList);
      for (let i = 0; type.length > i; i++) {
        this.$set(this.formDataToSend.type, type[i].id, false);
      }
    },
    async getAttributes() {
      this.formDataToSend.attributes = {};
      this.attributeArray = [];

      const url = `/${this.formDataToSend.category}`;

      await referencesApi
        .getTemplatesAttributesCategory(url)
        .then((res) => {
          this.attributeArray = res.data.items;
          this.setAttributes();
        })
        .catch((error) => {})
        .finally(() => {});
    },
    setAttributes() {
      const attributes = this.attributeArray;
      for (let i = 0; attributes.length > i; i++) {
        this.$set(this.formDataToSend.attributes, attributes[i].id, false);
      }
    },
    handleSubmit() {
      const data = {
        keyword: this.formDataToSend.keyword.trim(),
        category_id: this.formDataToSend.category,
        // requirements_link: this.formDataToSend.requirements_link.trim(),
        information_must_have: this.formDataToSend.information_must_have.trim(),
        key_points: this.formDataToSend.key_points.trim(),
        information_can_be_added: this.formDataToSend.information_can_be_added.trim(),
        pages: this.formDataToSend.pages.trim(),
        target_audience: this.formDataToSend.target_audience.trim(),
        preview_requirements: this.formDataToSend.preview_requirements.trim(),
        planned_labor_intensity_in_hours:
          this.formDataToSend.planned_labor_intensity_in_hours.trim(),
        parent_id: this.formDataToSend.parent_id,
        ai_requirements: this.formDataToSend.ai_requirements,

        template_requirements: this.formDataToSend.template_requirements,
        template_diagram: this.formDataToSend.template_diagram,
        template_overview: this.formDataToSend.template_overview,
        images: this.formDataToSend.images,
        typography: this.formDataToSend.typography,
        indents_and_margins: this.formDataToSend.indents_and_margins,
      };

      const examples = this.formDataToSend.examples.toLowerCase().split(/\r\n|\r|\n/g);
      const examplesSend = examples.filter((str) => str.trim() !== "");

      data.examples = examplesSend;

      const size = Object.keys(this.formDataToSend.size);
      if (size.length) {
        let sizeSend = [];
        for (let i = 0; size.length > i; i++) {
          if (this.formDataToSend.size[size[i]] == true) {
            sizeSend.push(size[i]);
          }
        }
        if (sizeSend.length > 0) {
          data.sizes = sizeSend;
        }
      }

      const attributes = Object.keys(this.formDataToSend.attributes);
      if (attributes.length) {
        let attributesSend = [];
        for (let i = 0; attributes.length > i; i++) {
          if (this.formDataToSend.attributes[attributes[i]] == true) {
            attributesSend.push(attributes[i]);
          }
        }
        if (attributesSend.length > 0) {
          data.attributes = attributesSend;
        }
      }

      const type = Object.keys(this.formDataToSend.type);
      if (type.length) {
        let typeSend = [];
        for (let i = 0; type.length > i; i++) {
          if (this.formDataToSend.type[type[i]] == true) {
            typeSend.push(type[i]);
          }
        }
        if (typeSend.length > 0) {
          data.types = typeSend;
        }
      }

      const months = Object.keys(this.formDataToSend.months);
      if (months.length) {
        let monthsSend = [];
        for (let i = 0; months.length > i; i++) {
          if (this.formDataToSend.months[months[i]] == true) {
            monthsSend.push(months[i]);
          }
        }
        if (monthsSend.length > 0) {
          data.seasonality = monthsSend;
        }
      }

      this.$store.dispatch("templates/createKeywords", data);
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.KEYWORDS_LIST.replace(":page", 1),
      });
    },
    getCategories() {
      if (this.categorySearch.trim().length > 0) {
        const data = {
          search: this.categorySearch.trim(),
        };
        try {
          categoriesApi.getCategoriesListFilter("", data).then((res) => {
            const data = res.data.items.data;
            this.categoriesList = data;
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          categoriesApi.getCategoriesActive().then((res) => {
            const data = res.data.items;
            this.categoriesList = data;
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
    debouncedGetCategories: debounce(function () {
      this.getCategories();
    }, 300),

    getParent() {
      const data = {};
      data.search = this.formDataToSend.parent_name.trim();
      data.perPage = 20;
      templateApi
        .getKeywordsListFilter(``, data)
        .then((res) => {
          const array = res.data.data.items.data;
          const setArray = [];
          for (let i = 0; array.length > i; i++) {
            if (!array[i].parentId) {
              setArray.push(array[i]);
            }
          }
          this.parentArray = array;

          this.parentArray.sort((a, b) => {
            if (a.keyword < b.keyword) {
              return -1;
            }
            if (a.keyword > b.keyword) {
              return 1;
            }
            return 0;
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    debouncedGetParent: debounce(function () {
      this.getParent();
    }, 300),

    handleFocusParent() {
      this.parentList = true;
      this.debouncedGetParent();
    },

    handleBlurParent() {
      setTimeout(() => {
        this.parentList = false;
        this.parentArray = [];
      }, 500);
    },

    handleChooseItemParent(item) {
      this.formDataToSend.parent_id = item.id;
      this.formDataToSend.parent_name = item.keyword;
    },

    handleAiRequirements() {
      if (this.formDataToSend.keyword.length > 2) {
        this.$store.commit("templates/setLoading", true);
        const url = `?keyword=${this.formDataToSend.keyword}`;
        try {
          templateApi.getAiRequirements(url).then((res) => {
            this.$store.commit("templates/setLoading", false);
            console.log(res);
            if (res.data.success) {
              this.formDataToSend.ai_requirements = res.data.data.response;
            }
          });
        } catch (error) {
          console.log(error);
          this.$store.commit("templates/setLoading", false);
        }
      }
    },

    beforeEnter(el) {
      el.style.height = "0";
      el.style.opacity = "0";
    },
    enter(el, done) {
      const height = el.scrollHeight + "px";
      el.style.transition = "height 0.3s ease, opacity 0.3s ease";
      el.style.height = height;
      el.style.opacity = "1";
      el.addEventListener("transitionend", done);
    },
    leave(el, done) {
      el.style.transition = "height 0.3s ease, opacity 0.3s ease";
      el.style.height = "0";
      el.style.opacity = "0";
      el.addEventListener("transitionend", done);
    },
    toggleAllFields() {
      this.isAllFields = !this.isAllFields;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-select {
  max-width: 300px;
}
.checkbox-container {
  @include scrollBar;

  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;

  &_margin {
    margin-top: 20px;
  }
}
.checkbox-container__item {
  padding: 5px 0;
}

.form-create {
  &__all-button {
    margin: 20px 0;
    padding: 10px 20px;
    background: #e5e5e7;
    font-size: 20px;
    border-radius: 5px;
    width: fit-content;
    cursor: pointer;

    svg {
      margin-left: 10px;
    }

    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__all-container {
    background: #f5f5f5;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 30px;
  }

  &__search {
    font-size: 18px;
    margin-top: 20px;
    width: 400px;
    padding: 5px 10px;
  }

  &__keyword-input {
    width: 100%;
    font-size: 18px;
    padding: 5px 10px;
    margin-bottom: 10px;
  }

  &__keyword {
    position: relative;
    margin-bottom: 10px;
  }

  &__keyword-list {
    position: absolute;
    left: 0;
    top: calc(100% + 5px);
    border: 1px solid gray;
    background: #fff;
    padding: 10px 0 10px;
    z-index: 10;
  }

  &__keyword-item {
    font-size: 16px;
    padding: 5px 20px;
    cursor: pointer;

    &:hover {
      background: rgb(216, 213, 213);
    }
  }
}
</style>
